<template>
  <aside class="sidebar-sm">
    <div class="sidebar-sm_user">
      <div class="user-avatar">
        <img
          id="avatar"
          style="border-radius: 50%"
          src="../../../../assets/img/profile.svg"
          alt="logo"
          srcset=""
        />
      </div>
      <h5 class="user-name" style="word-wrap: anywhere; text-align: left">{{ groupName }}</h5>
      <button
        @click="$refs.FileInput.click()"
        class="button w-100 button--success-outline text--small"
      >
        Profile photo
      </button>
      <input
        ref="FileInput"
        type="file"
        style="display: none;"
        @change="onFileSelect"
      />
    </div>
    <nav class="sidebar-sm_links">
      <ul>
        <li class="sidebar-sm__link">
          <a
            v-if="switchTabFnProp !== null"
            class="sidebar-sm__item sidebar-sm__link--active"
            @click="switchTabFn('OverViewTab')"
          >
            <span class="sidebar-sm__link__title text--capital">
              overview
            </span>
          </a>
          <a v-else class="sidebar-sm__item" @click="viewGroup(groupKey)">
            <span class="sidebar-sm__link__title text--capital">
              overview
            </span>
          </a>
        </li>
        <li class="sidebar-sm__link has-submenu">
          <a class="sidebar-sm__item">
            <span class="sidebar-sm__link__title text--capital">
              Accounts
            </span>
            <ion-icon
              class="sidebar__link__subtitle"
              name="chevron-forward-outline"
            ></ion-icon>
          </a>
          <div class="submenu">
            <ul
              class="sidemenu__container"
              v-if="
                groupLoanDeposit && groupLoanDeposit.operationalAccount && groupLoanDeposit.operationalAccount.length
              "
            >
              <li
                class="submenu__item"
                v-for="account in groupLoanDeposit.operationalAccount"
                :key="account.accountKey"
                v-show="accountKey != account.accountKey"
              >
                <a
                  v-if="account.accountType == 'Loan'"
                  @click="viewLoanAccount(account.accountKey)"
                  tag="a"
                  class="submenu__link"
                  >{{ account.accountName }}</a
                >
                <a
                  v-else
                  @click="viewDepositAccount(account.accountKey)"
                  tag="a"
                  class="submenu__link"
                  >{{ account.accountName }}</a
                >
              </li>
            </ul>
            <ul v-else>
              <li>no data available</li>
            </ul>
          </div>
        </li>
        <li class="sidebar-sm__link has-submenu">
          <a class="sidebar-sm__item">
            <span class="sidebar-sm__link__title text--capital">
              Closed Accounts
            </span>
            <ion-icon
              class="sidebar__link__subtitle"
              name="chevron-forward-outline"
            ></ion-icon>
          </a>
          <div class="submenu">
            <ul
              class="sidemenu__container"
              v-if="groupLoanDeposit && groupLoanDeposit.closedAccount && groupLoanDeposit.closedAccount.length"
            >
              <li
                class="submenu__item"
                v-for="account in groupLoanDeposit.closedAccount"
                :key="account.accountKey"
              >
                <a
                  v-if="account.accountType == 'Loan'"
                  @click="viewLoanAccount(account.accountKey)"
                  tag="a"
                  class="submenu__link"
                  >{{ account.accountName }}</a
                >
                <a
                  v-else
                  @click="viewDepositAccount(account.accountKey)"
                  tag="a"
                  class="submenu__link"
                  >{{ account.accountName }}</a
                >
              </li>
            </ul>
            <ul v-else>
              <li>no data available</li>
            </ul>
          </div>
        </li>
        <li class="sidebar-sm__link" v-if="userCanViewTasks">
          <a class="sidebar-sm__item" @click="goToGroupTask(groupKey)">
        <!-- <li class="sidebar-sm__link" v-if="userCanViewTask">
          <a class="sidebar-sm__item" @click="switchTabFn('TaskViewTab')"> -->
            <span class="sidebar-sm__link__title text--capital"> Tasks </span>
          </a>
        </li>
      </ul>
    </nav>

    <div
      class="modal modal__confirm show"
      id="cropImageModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: dialog ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Crop Image
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="closeModal()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <div class="form__label">
                <VueCropper
                  v-show="selectedFile"
                  ref="cropper"
                  :src="selectedFile"
                  alt="Profile Image"
                ></VueCropper>
              </div>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="closeModal()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="saveImage()"
                >
                  {{ isSaving ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import ApiService from "@/core/services/general.service";
import VueCropper from "vue-cropperjs"
import * as defaultUrl from "../../../../assets/img/profile.svg";
import "cropperjs/dist/cropper.css";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";

export default {
  name: "GroupAside",
  props: [
    "groupName",
    "groupKey",
    "switchTabFnProp",
    "groupLoanDeposit",
    "accountKey",
  ],
  components: { VueCropper },
  data: function () {
    return {
      switchTabFn: this.switchTabFnProp,
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      isSaving: false,
      currentUrl: "",
      documentKey: null,
    };
  },
  mounted() {
    this.subMenu();
    this.getDisplayImage()
  },
  methods: {
    viewGroup(key) {
      this.$router.push({ path: `/group/${key}` });
    },
    viewDepositAccount(key) {
      this.$router.push({ path: `/deposit-account/${key}` });
    },
    viewLoanAccount(key) {
      this.$router.push({ path: `/loan/accounts/${key}` });
    },
    subMenu() {
      const sidemnus = document.querySelectorAll(".has-submenu");

      sidemnus.forEach((element) => {
        element.addEventListener("mouseenter", function () {
          const distanceTop =
            window.pageYOffset + element.getBoundingClientRect().top;
          const sideElementWidth = element.offsetWidth;
          const distanceLeft =
            window.pageXOffset + element.getBoundingClientRect().left;
          const newOffset = distanceLeft + sideElementWidth;
          element.lastElementChild.style.transform = `translate3d(${newOffset}px, ${distanceTop}px, 0)`;
        });
      });
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.mime_type = file.type;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        this.$emit("imageAlert", {
          type: "error",
          message: "Sorry, FileReader API not supported",
        });
        // alert('Sorry, FileReader API not supported')
      }
    },
    displayImage(url) {
      let img = url ? url : defaultUrl.default;
      document.getElementById("avatar").src = img;
    },
    getDisplayImage() {
      this.currentUrl = ""
      ApiService.get(`Customer/GetCustomerProfilePicture?customerKey=${this.groupKey}&documentType=GroupProfile`)
        .then((response) => {
          let data = response.data.data;
          this.currentUrl = data.attachment && data.attachment.fileUrl ? data.attachment.fileUrl : "";
          this.documentKey = data.documentKey ? data.documentKey : "";
          this.displayImage(this.currentUrl);
        })
        .catch(function () {
          // console.log(error.response);
        });
    },
    saveImage() {
      this.isSaving = true;
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.displayImage(this.cropedImage);
      const name = `${this.groupName.replace(" ", "_")}.jpeg`;
      const baseUrl = process.env.VUE_APP_API_BASE_GENERAL_URL;
      let self = this;
      const url = this.documentKey
        ? `${baseUrl}Customer/UploadCustomerProfilePicture/${this.groupKey}?accountType=GroupProfile&createdBy=${""}&documentKey=${this.documentKey}`
        : `${baseUrl}Customer/UploadCustomerProfilePicture/${this.groupKey}?accountType=GroupProfile`;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob, name);
        formData.append("description", "profile picture");
        ApiService.postFormData(url, formData)
          .then((response) => {
            let data = response.data.message;
            this.getDisplayImage();
            self.closeModal();
            self.$emit("imageAlert", {
              type: "success",
              message: data, 
            });
          })
          .catch(function (error) {
            self.isSaving = false;
            self.displayImage(self.currentUrl);
            self.$emit("imageAlert", {
              type: "error",
              message: error.response.data.message, 
            });
          });
      }, this.mime_type);
    },
    closeModal() {
      this.mime_type = "";
      this.autoCrop = false;
      this.selectedFile = "";
      this.image = "";
      this.dialog = false;
      this.files = "";
      this.isSaving = false;
    },
    goToGroupTask(key) {
      this.$router.push({ path: `/all-group-tasks/${key}` });
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanViewTasks() {
      const account = this.user.scopes.find(item => item.scopeName === Role.Tasks);
      const { permissionsList } = account;
      return permissionsList.CanViewTask;
    },
  },
};
</script>


export default {
  data() {
    return {
      defDepositAmount: '',
    }
  },
  methods: {
    inputCurrencyFormat(num = 0) {
      const number = (num / 1).toFixed(2).replace(',', '.');
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    applyFeeInputCurrencyFormat(num) {
      if (num || num == 0) {
        const number = (num / 1).toFixed(0).replace(',', '.');
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return "";
      }
    },

    inputInterestFormat(num) {
      const number = (num / 1).toFixed(2).replace(',', '.');
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    isValidNumberInput(amount) {
      const formattedInput = amount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g);
      return formattedInput === 'NaN' ? false : true;
    },
    
    validateDepositAmount(amount) {
      if (!this.isValidNumberInput(amount)) {
        this.defDepositAmount = 'Invalid amount, Please provide amount in figures';
        return false;
      }
      const pAmt = this.convertFigureToNumber(amount);
      if (pAmt < 1) {
        this.defDepositAmount = 'Invalid amount, Please provide a valid amount in figures';
      }
      this.defDepositAmount = '';
      return true;
    },

    convertFigureToNumber(number) {
      const stringValueWithNoSpaces = number.replace(/,/g, '');
      const numberToTwoDecimalPlaces = Number(stringValueWithNoSpaces).toFixed(2);
      return Number(numberToTwoDecimalPlaces);
    },
  }
}